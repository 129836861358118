<template>
    <div class="explore_albums_nav">
        <div class="left_buttons" v-if="!STATUS_FILTER || STATUS_FILTER === false">
            <button type="button" class="view_list" @click="changeVisibleAlbums('list')" :class="{'active': VIEW_ALBUMS === 'list'}"></button>
            <button type="button" class="view_grid" @click="changeVisibleAlbums('grid')" :class="{'active': VIEW_ALBUMS === 'grid'}"></button>
        </div>

        <template v-if="isAdmin">
            <button type="button" @click="selectionAlbums('pending')" :class="{active: UPPER_FILTER === 'pending'}">Pending</button>
        </template>

        <template v-if="AUTH_DATA.accessToken">
            <button type="button" @click="selectionAlbums('undiscovered')" :class="{active: UPPER_FILTER === 'undiscovered'}">Discover</button>
        </template>

        <button type="button" @click="selectionAlbums('explore')" :class="{active: UPPER_FILTER === 'explore'}">Explore</button>
        <button type="button" @click="selectionAlbums('popular')" :class="{active: UPPER_FILTER === 'popular'}">Popular</button>
        <button type="button" @click="selectionAlbums('trending')" :class="{active: UPPER_FILTER === 'trending'}">Trending</button>

        <template v-if="AUTH_DATA.accessToken">
            <button type="button" @click="selectionAlbums('favorite')" :class="{active: UPPER_FILTER === 'favorite'}">Favorite</button>
        </template>


        <div class="right_buttons">
            <button type="button" v-if="STATUS_FILTER === true" class="bookmarks" @click="bookmarks">Bookmarks</button>
            <button type="button" class="show_clear_filter" :class="{'active': STATUS_FILTER === true}" @click="toggleFilter">
                {{ (STATUS_FILTER === true) ? 'Clear' : 'Filters' }}
            </button>
        </div>
        <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import eventBus from "@/eventBus";

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");

export default {
    name: "albumsNav",
    components: {
        ComingSoonDialog
    },
    data() {
        return {
            showComingSoonDialog: false,
        }
    },
    watch: {
        UPPER_FILTER: {
            handler(current, prev) {
                if (current === 'favorite') {
                    this.$store.commit('SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE', 1);
                } else {
                    this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
                    this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
                }
                this.$store.commit('SET_ALBUM_EXPANDED', '');
            }
        },
    },
    computed: {
        ...mapGetters(['isAdmin', 'AUTH_DATA', 'UPPER_FILTER', 'STATUS_FILTER', 'VIEW_ALBUMS', 'ALBUM_EXPANDED', 'ALBUMS_CURRENT_PAGE'])
    },
    created() {
        if (!this.UPPER_FILTER) {
            if (this.AUTH_DATA.accessToken) {
                this.$store.dispatch('UPDATE_UPPER_FILTER', 'undiscovered');
            } else {
                this.$store.dispatch('UPDATE_UPPER_FILTER', 'explore');
            }
        }
    },
    mounted() {
        if (this.UPPER_FILTER === 'pending') {
            this.$store.commit('SET_VIEW_ALBUMS', 'list');
        }
    },
    methods: {
        selectionAlbums(value) {
            this.$store.dispatch('UPDATE_UPPER_FILTER', value);
        },
        toggleFilter() {
            if (this.STATUS_FILTER === true) {
              this.$store.commit('SET_STATUS_FILTER', false);
              this.$store.dispatch('CLEAN_FILTERS');
              this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
              this.$store.commit('SET_ALBUM_EXPANDED','');
	            this.$store.commit('SET_GRID_ALBUM_SCROLL_ID', null);
	            this.$store.commit('SET_TRACK_SCROLL_ID', null);
            } else {
                this.$store.commit('SET_STATUS_FILTER', true);
            }
        },
        changeVisibleAlbums(value) {
            this.$store.commit('SET_VIEW_ALBUMS', value);
        },
        bookmarks() {
            this.showComingSoonDialog = true;
        }
    }
}
</script>

<style scoped>

</style>
