<template>
	<exploreLayout>
	  <div class="explore">
		<div class="head_banner">
		  <v-container full-height class="full-height">
			<h3 v-if="!subgenre">explore psychedelic trance</h3>
			<h3 v-else>{{ SUBGENRE.name }}</h3>
			<nav class="explore_nav" ref="explore_nav" id="explore-nav-id">
			  <ul>
				<li>
				  <button type="button" @click="selectSubGenre({})" :class="{'active': !SUBGENRE}">All</button>
				</li>
				<li v-for="item in SUBGENRES" :key="item.id" :class="{'active': SUBGENRE.name === item.name}" ref="subgenrePosition">
				  <button type="button" @click="selectSubGenre(item)">{{ item.name }}</button>
				</li>
				<div class="animation_active_li" :style="{left: activeSubgenrePosition + 'px', width: activeSubgenreWidth + 'px'}"
					 :class="SUBGENRE">
				</div>
			  </ul>
			</nav>
		  </v-container>
		</div>
		<div class="container full_page">
		  <template v-if="mobile">
			<albumsNavMobile v-if="AUTH_DATA.accessToken"></albumsNavMobile>
			<albumsFilterMobile :actions="false" :subgenreFilter="'dropdown'" :keysFilter="true" :filterName="'explore'"></albumsFilterMobile>
			<albumsList :whatAlbums="'all'" v-if="showAlbumsOrTracks === 'albums'"></albumsList>
			<trackList v-if="showAlbumsOrTracks === 'tracks'"></trackList>
		  </template>
		  <template v-else>
			<albumsNav></albumsNav>
			<albumsFilter :actions="false" :subgenreFilter="'dropdown'" :keysFilter="true" :filterName="'explore'"></albumsFilter>
			<albumsList :whatAlbums="'all'" v-if="showAlbumsOrTracks === 'albums'"></albumsList>
			<trackList v-if="showAlbumsOrTracks === 'tracks'"></trackList>
			<googleAdsense></googleAdsense>
		  </template>
		</div>
	  </div>
	</exploreLayout>
  </template>
  
  <script>
  import {mapGetters, mapActions} from 'vuex';
  import exploreLayout from "@/layouts/exploreLayout.vue";
  import albumsNav from '@/components/musicReleases/albumsNav';
  import albumsFilter from '@/components/musicReleases/albumsFilter';
  import albumsList from "@/components/musicReleases/albumsList";
  const trackList = () => import('@/components/musicReleases/trackList');
  import googleAdsense from "@/components/googleAdsense";
  
  // mobile
  import mainSubgenres from "@/components/mobile/mainSubgenres";
  import isMobile from "@/mixins/isMobile";
  import albumsNavMobile from '@/components/musicReleases/albumsNavMobile';
  import albumsFilterMobile from '@/components/musicReleases/albumsFilterMobile';
  
  export default {
	name: 'explore',
	props: ['subgenre'],
	components: {
	  exploreLayout,
	  albumsNav,
	  albumsNavMobile,
	  albumsFilterMobile,
	  albumsFilter,
	  albumsList,
	  trackList,
	  googleAdsense,
	  mainSubgenres,
	},
	mixins: [isMobile],
	data: () => ({
	  showAlbumsOrTracks: 'albums',
	  activeSubgenrePosition: '',
	  activeSubgenreWidth: '',
	  mobileSubgenresLoading: false,
	  ogTitle: 'Psychurch',
	  ogDescription: 'Psychurch - Psychedelic Trance Music',
	  dynamicKey: 0,
	}),
	metaInfo() {
	  return {
		title: 'Psychurch',
		meta: [
		  { property: 'og:title', content: this.ogTitle},
		  { property: 'og:description', content: this.ogDescription},
		  { name: 'description', content: this.ogDescription},
		  { property: 'og:image', content: 'https://dev.edmverse.com/img/facebook/open_gaph_img.png.png'},
		  { property: 'og:site_name', content: 'Psychurch'},
		  { property: 'og:type', content: 'website'},
		  { name: 'robots', content: 'index'}
		]
	  }
	},
	watch: {
	  BPM() {
		this.visibleAlbumsOrTracks();
	  },
	  APPLY_KEYS() {
		this.visibleAlbumsOrTracks();
	  },
	  SUBGENRE() {
		if (this.SUBGENRES.length) {
		  this.$nextTick(() => {
			this.activeSubgenre();
		  });
		}
	  },
	  '$route' (to, from) {
		if (to.fullPath === '/') {
		  this.$store.dispatch('UPDATE_SUBGENRE', {});
		}
	  }
	},
	computed: {
	  ...mapGetters([
		'PLAYING',
		'SEARCH_STRING',
		'AUTH_DATA',
		'STATUS_FILTER',
		'COUNT_MUSIC_RELEASE', 'MUSIC_RELEASE', 'ALBUMS_CURRENT_PAGE',
		'SUBGENRE', 'BPM', 'KEYS', 'APPLY_KEYS', 'TAGS', 'ACTIVE_SAVED_FILTER', 'UPPER_FILTER',
		'ORDER_BY',
		'VIEW_ALBUMS', 'ALBUM_EXPANDED', 'SAVED_FILTERS', 'SUBGENRES',
		'CURRENT_PAGE', 'GRID_ALBUM_SCROLL_ID'])
	},
	mounted() {
	  this.visibleAlbumsOrTracks();
	  if (!this.SUBGENRES.length) {
		this.getSubgenres();
	  } else {
		this.activeSubgenre();
		if (this.SUBGENRE) {
		  this.selectSubGenre(this.SUBGENRE);
		}
	  }
	},
	methods: {
	  ...mapActions(['GET_MUSIC_RELEASE', 'CHANGE_FILTERS', 'GET_SUBGENRES']),
	  visibleAlbumsOrTracks() {
		if (this.BPM.length || this.APPLY_KEYS.length) {
		  this.showAlbumsOrTracks = 'tracks';
		} else if (!this.BPM.length && !this.APPLY_KEYS.length) {
		  this.showAlbumsOrTracks = 'albums';
		}
	  },
	  selectSubGenre(item) {
		this.$store.commit('SET_SUBGENRE', item);
		if (item.name) {
		  const subgenre = item.name.trim().replace(/ /g, '-');
		  this.$router.replace({path: `/explore/${subgenre}`});
		} else {
		  this.$router.push({path: '/'});
		}
		this.getSubgenres();
	  },
	  getSubgenres() {
		this.mobileSubgenresLoading = true;
		const subgenresParams = {
		  genre: 2,
		  'order[musicReleasesCount]': 'DESC'
		}
		this.GET_SUBGENRES(subgenresParams)
		  .then(() => {
			this.dynamicKey += 1;
			if (this.SUBGENRES.length && this.subgenre) {
			  const subgenre = this.SUBGENRES.find(el => el.name.trim().replace(/ /g, '-') === this.subgenre);
			  if (subgenre) {
				this.$store.commit('SET_SUBGENRE', subgenre);
			  } else {
				this.$store.commit('SET_SUBGENRE', {});
				this.$router.push({path: '/'})
			  }
			  this.activeSubgenre();
			}
		  })
		  .catch(err => console.error(`GET_SUBGENRES, ${err}`))
		  .finally(() => {
			this.mobileSubgenresLoading = false;
		  });
	  },
	  activeSubgenre() {
		if (!this.mobile) {
		  this.$nextTick(() => {
			let active = this.$refs['subgenrePosition'].find(el => el._prevClass === 'active');
			if (active) {
			  this.activeSubgenrePosition = active.offsetLeft;
			  this.activeSubgenreWidth = active.offsetWidth;
			} else {
			  this.activeSubgenrePosition = 6;
			  this.activeSubgenreWidth = 70;
			}
		  });
		}
	  }
	},
  };
  </script>
  